body {
    font-family: 'Roboto', sans-serif;
}

.igdHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 0;
    border-bottom: 1px solid #ccc;
}

.headerLogo {
    width: 200px;
    margin-right: 28px;
}

.headerTitle {
    font-weight: 900;
    font-size: 42px;
}

.sidebar {
    padding-top: 22px;
}

.search {
    position: relative;
    margin-bottom: 22px;
}

.search .glyphicon-search {
    position: absolute;
    right: 12px;
    top: 10px;
    color: #54CACA;
}

.sidebarTitle {
    color: #34559A;
    font-size: 16px;
    font-weight: 600;
    padding: 22px 0;
}

.productcat {
    margin-top: 22px;
}

.productcat .panel-default>.panel-heading.section-heading {
    color: #34559A;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
}

.productcat .panel-default>.panel-heading .panel-title a {
    display: flex;
    justify-content: space-between;
    outline: none;
}

.productcat .panel-default>.panel-heading .panel-title.collapsed .glyphicon-menu-down {
    transform: rotate(180deg);
}

.productcat .panel-default>.panel-heading .panel-title a:hover {
    text-decoration: none
}

.productcat .panel-body .productList {
    padding: 0;
    margin: 0;
    list-style: none;
}

.productcat .panel-body {
    height: 155px;
    overflow-y: scroll;
}

.productcat .panel-body .productList li {
    margin: 6px 0;
}

.sortBy {
    float: right;
    margin: 22px 0;
    width: 150px;
}

.productImg {
    margin-bottom: 18px;
}

.productImg img {
    width: 100%;
    box-shadow: 0px 0px 11px #eaeaea;
    border-radius: 6px;
}

.productCard {
    margin-bottom: 32px;
}

.productCard .productDiscription {
    display: flex;
    flex-direction: column;
}

.productCard .productDiscription .producctTitle {
    color: #34559A;
    font-size: 18px;
    margin-bottom: 12px;
}

.productCard .productDiscription .productdisc {
    font-size: 14px;
    color: #000;
    margin-bottom: 12px;
    height: 60px;
    overflow: hidden;
}

.productCard .productDiscription .price {
    color: #34559A;
    font-size: 18px;
    margin-bottom: 12px;
}

.productPurchase {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.productPurchase .btn-primery {
    font-size: 18px;
    font-weight: 600;
    background-color: #53CBCB;
    padding: 6px 12px;
    color: #fff;
}

.productPurchase .btn-sec {
    font-size: 18px;
}

.logoList {
    margin-top: 100px;
    padding: 0 50px;
}

.logoList h1 {
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 50px;
    color: #34559A;
    margin-bottom: 32px;
}

.logos {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
}

.logos img {
    margin: 0 12px;
    width: 15em;
}

footer {
    background: #34559A;
    padding: 22px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    margin-top: 52px;
}


/*View Detail Popup*/

.view-product-modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 13;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    visibility: visible;
    overflow-y: scroll;
}
.productPopup{
    display: flex;
}
.productPopup .productImgPopup, .productPopup .productDetailsPopup{
    width: 50%;
    padding: 22px;
}
/* .productDetailsPopup{border-left: 2px solid #000;} */
.productPopup .productImgPopup img{
    width: 100%;
}

.productPopup .productDetailsPopup .topDetails .productTitlePopup{
    color: #315698;
    font-size: 28px;
    font-weight: bold;

}
.productPopup .productDetailsPopup .topDetails .producctSmallDisc{
    font-size: 20px;
    margin: 6px 0;
    display: block;
}
.productPopup .productDetailsPopup .topDetails .productCode{font-size: 17px;}
.productPopup .ProductPrice{
    color: #315698;
    font-size: 28px;
    font-weight: bold;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 6px 0;
    margin: 12px 0;
}
.productPopup .productWarranty{
    font-size: 17px;
    margin-bottom: 50px;
}
.productPopup .productWarranty span{font-weight: bold;}
.productPopup .cartQuantity{display: flex; font-size: 16px; margin-bottom: 12px;}
.productPopup .cartQuantity span{margin-right: 12px;}
.productPopup .cart-quantity input{width: 20%; text-align: center;}
.buyPopupBtn{    display: flex;
    margin: 22px 0;}
.buyPopupBtn .btn-primery{
    font-size: 22px;
    font-weight: 600;
    background-color: #53CBCB;
    padding: 6px 12px;
    color: #fff;
    width: 100%;
    text-align: center;
}
#productDetails .modal-body{padding: 0;}
#productDetails .modal-dialog{margin:70px auto}
#productDetails .modal-body .close{position: absolute;
    right: 0;
    top: -48px;
    background: #53CBCB;
    opacity: 1;
    color: #fff;
    text-shadow: 0 0px 0 #fff;
    width: 50px;
    height: 50px;
    font-weight: 300;
    font-size: 28px;
    padding: 0;
    margin: 0;
    text-align: center;
    border-radius: 6px 6px 0 0;
    z-index: -1;}
.productDetailsPopupDown h3{
    font-size: 18px;
    color: #000;
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    margin: 0;
    padding: 12px 0;
}
.productDetailsPopupDown{
    /* border-top:2px solid #000; */
    padding: 0px 22px 22px 22px;
}
.productDetailsPopupDown p{font-size: 16px; color: #000; margin-top: 12px;}

.productCard .productImg {
    margin-bottom: 18px;
    max-height: 180px;
    text-align: center;
    border: solid 1px #ccc;
    border-radius: 10px;
    overflow: hidden;
}

.productCard .productImg img {
    max-width: 100%;
    box-shadow: 0px 0px 11px #eaeaea;
    border-radius: 6px;
    max-height: 180px;
}



@media(max-width: 992px){
   
    .productPopup .productImgPopup, .productPopup .productDetailsPopup{width: 100%;}
    .productDetailsPopup{border-top:2px solid #000; border-left: 0;}

}
@media(max-width: 767px) {
    .igdHeader {
        flex-direction: column;
    }
    .headerTitle {
        text-align: center;
    }
    .productPopup{flex-direction: column;}
    .productPopup .productImgPopup, .productPopup .productDetailsPopup{width: 100%;}
    .productDetailsPopup{border-top:2px solid #000; border-left: 0;}
}