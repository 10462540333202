.dropbtn {
    background-color: #ffffff;
    color: #315698;
    padding: .1em .8em;
    font-size: 2em;
    border: solid 1px #315698;
    border-radius: 1em;
    float: right;
    .dp-icon{
        font-size: 1em;
        margin-right: .3em;
    }
}

.dropdown {
    position: absolute;
    display: inline-block;
    right:5em;
    top:3.5em;
    z-index: 2;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    border-radius: 1em;
    top:3.5em;
}

.dropdown-content a {
    color: black;
    padding: .4em .8em;
    text-decoration: none;
    display: block;
    font-size: 1.5em;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #315698;
    color: #ffffff;
}