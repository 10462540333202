.react-datepicker {
  width: 10em;
  font-size: 1rem !important;
}
.react-calendar {
  width: 30em !important;
  overflow: hidden !important;
  border-radius: 1em !important;
  line-height: 1em !important;
}
.react-date-picker {
  width: 85%;
  height: 2em;
}
