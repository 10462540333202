// @import "compass";

// Stepper
.stepper {
    .nav {
        margin: 12% 0 0 20%;
        padding-left: 0;
        border-bottom: 0;

        li {
            text-align: left;
            margin: 0 0 10% 0;
            cursor: pointer;

            div {
                color: #92a9d3;
                display: block;
                font-size: 2em;
                margin: 0 2em 1em 2em;
                position: relative;
                padding: 0 0 0 1.35em;
                transition: color 0.5s ease-in-out;
            }
        }

        li:after {
            //bullets
            background: transparent;
            border: 2px solid #53cbca;
            border-radius: 30px;
            content: '';
            left: 0;
            height: 20px;
            position: absolute;
            top: 4px;
            width: 20px;
        }

        li.active:after {
            background: radial-gradient(circle, rgba(83, 203, 202, 1) 0%,rgba(83, 203, 202, 1) 50%, rgba(49, 86, 152, 1) 50%);
            padding: 5px;
        }

        li.selected:after {
            background: radial-gradient(circle, rgba(83, 203, 202, 1) 100%, rgba(49, 86, 152, 1) 100%);
            padding: 5px;
        }

        li.active:before {
            // lines
            content: "";
            position: absolute;
            left: 8.5px; //adjust manually
            top: 30px;
            border-left: 2px solid #53cbca;
            height: 100%;
            width: 1px;
        }

        li:last-child:before {
            /* last li's line */
            height: 0px;
        }
    }

}