
.main{
    position: relative;
    height: 100vh;
}
.container{
    overflow: hidden;
}
.profile-nav {
    margin-top: 0em;
}
.profile-info,
.booking-details,
.membership-details {
    margin-top: 15em;
}
.profile-nav .user-heading {
    color: #315698;
    background-color: #fff;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    padding: 30px;
    text-align: center;
}

.profile-nav .user-heading.round a {
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 10px solid rgba(255, 255, 255, 0.3);
    display: inline-block;
}

.profile-nav .user-heading a img {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

.profile-nav .user-heading h1 {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 5px;
}

.profile-nav .user-heading p {
    font-size: 12px;
}

.profile-nav ul {
    margin-top: 1px;
}

.profile-nav ul>li {
    border-bottom: 1px solid #ebeae6;
    margin-top: 0;
    line-height: 30px;
}

.profile-nav ul>li:last-child {
    border-bottom: none;
}

.profile-nav ul>li>a {
    border-radius: 0;
    -webkit-border-radius: 0;
    color: #89817f;
    border-left: 5px solid #fff;
}

.profile-nav ul>li>a:hover,
.profile-nav ul>li>a:focus,
.profile-nav ul li.active a {
    background: #f8f7f5 !important;
    border-left: 5px solid #315698;
    color: #89817f !important;
}

.profile-nav ul>li:last-child>a:last-child {
    border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
}

.profile-nav ul>li>a>i {
    font-size: 16px;
    padding-right: 10px;
    color: #bcb3aa;
}

.r-activity {
    margin: 6px 0 0;
    font-size: 12px;
}


.p-text-area,
.p-text-area:focus {
    border: none;
    font-weight: 300;
    box-shadow: none;
    color: #c3c3c3;
    font-size: 16px;
}

.profile-info .panel-footer {
    background-color: #f8f7f5;
    border-top: 1px solid #e7ebee;
}

.profile-info .panel-footer ul li a {
    color: #7a7a7a;
}
.control-label{
    font-size: 1.4em;
}
.bio-graph-heading {
    background: #315698;
    color: #fff;
    text-align: center;
    font-style: italic;
    padding: 40px 110px;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
    font-size: 16px;
    font-weight: 300;
}

.bio-graph-info {
    color: #89817e;
}

.bio-graph-info h1 {
    font-size: 22px;
    font-weight: 300;
    margin: 0 0 20px;
}

.bio-row {
    width: 100%;
    float: left;
    margin-bottom: 10px;
    padding: 0 15px;
}

.bio-row p span {
    width: 100px;
    display: inline-block;
}

@media (min-width:320px) {
    .igdHeader {
        flex-direction: column;
    }

    .headerTitle {
        text-align: center;
    }
}