.overlay02 {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 13;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 1;
    visibility: hidden;
    transition: .3s linear;
    transform: translateY(-100%);
    overflow-y: auto;
}

.overlay02.is-open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.loginBtn {
    font-size: 2em;
    color: #315698;
    width: '3em';
    height: '1em';
    
    border: 'solid 2px #315698';
    border-radius: '10px';
}